import { TranslateService } from '@ngx-translate/core';
import { APP_INITIALIZER, Provider } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { AppConfigService } from '../app-config.service';
import { CONFIG_URL } from '../urls';
import { AuthGuard, AuthService, LoginGuard } from '../auth';
import { Title } from '@angular/platform-browser';
import { FileService, LocalStorageService, SharedService } from '../shared';
import { UserResolver } from '../user.resolver';
import { RouterExtService } from '../router-ext.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { WsService } from '../ws.service';
import { SchedulerService } from '../scheduler/scheduler.service';
import { WorkflowService } from '../workflow/workflow.service';
import { PatientService } from '../patient/patient.service';
import { ReportingService } from '../reporting/reporting.service';
import { SettingService } from '../setting/setting.service';
import { StatisticService } from '../statistic/statistic.service';
import { TokenInterceptor } from '../auth/interceptors/token.interceptor';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import localeNl from '@angular/common/locales/nl-BE';
import localeFrExtra from '@angular/common/locales/extra/fr';
import localeNlExtra from '@angular/common/locales/extra/nl-BE';
import { NUMERAL_FR_SPEC } from '../utils';
import * as numeral from 'numeral';
import { PhysicianService } from '../physicians/physician.service';

registerLocaleData(localeFr, 'fr-FR', localeFrExtra);
registerLocaleData(localeNl, 'nl-NL', localeNlExtra);


function _setupMaterial(
	_matIconRegistry: MatIconRegistry,
	_config: AppConfigService,
) {
	_matIconRegistry.registerFontClassAlias('mdi', 'mdi');

	numeral.register('locale', 'fr', NUMERAL_FR_SPEC);
	numeral.locale(_config.appLang);
}

export function provideSharedConfig(): Provider[] {
	return [
		Title,
		FileService,
		LoginGuard,
		AuthGuard,
		AuthService,
		UserResolver,
		RouterExtService,
		WsService,
		SchedulerService,
		WorkflowService,
		PatientService,
		ReportingService,
		SettingService,
		SharedService,
		LocalStorageService,
		StatisticService,
		PhysicianService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: TokenInterceptor, multi: true,
		},
		{
			provide: APP_INITIALIZER,
			deps: [AppConfigService, TranslateService],
			useFactory: (config: AppConfigService) => () => config.load(CONFIG_URL),
			multi: true,
		},
		{
			multi: true,
			provide: APP_INITIALIZER,
			deps: [MatIconRegistry, AppConfigService],
			useFactory: (matIconRegistry: MatIconRegistry, config: AppConfigService) => () => _setupMaterial(matIconRegistry, config),
		},
	];
}
