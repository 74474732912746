import { Component } from '@angular/core';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import {DEFAULT_SEARCH_CONFIG, PriorityDTO} from '../../model';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { assign } from 'lodash';
import {SearchService} from "../../shared/advanced-search/search.service";
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton, MatFabButton } from '@angular/material/button';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from '@angular/material/expansion';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-priority-setting',
    templateUrl: './priority-setting.component.html',
    styleUrls: ['./priority-setting.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatChipListbox,
        MatChipOption,
        MatExpansionPanelDescription,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        ColorPickerModule,
        MatButton,
        MatFabButton,
        MatTooltip,
        MatIcon,
        TranslateModule,
    ],
})
export class PrioritySettingComponent {
	priorities: PriorityDTO[] = [];
	priorityForm!: FormGroup;
	currentPriorityColor: string = '#25ac5d';

	constructor(
		private service: SharedService,
		public dialog: MatDialog,
		private fb: FormBuilder
	) {
		this.priorityForm = this.fb.group(
			assign(new PriorityDTO(), {
				value: [null, Validators.required],
			})
		);
		this.getPriorities();
	}

	private getPriorities(): void {
		this.service
			.getPriorities()
			.subscribe(data => (this.priorities = data));
	}

	savePriority(priority: PriorityDTO) {
		priority.color = this.currentPriorityColor;
		this.service.createPriority(priority).subscribe(res => {
			if (res) this.getPriorities();
		});
	}

	deletePriority(priority: PriorityDTO) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok)
					this.service
						.deletePriority(priority.id)
						.subscribe(value => {
							if (value) this.getPriorities();
						});
			});
	}

	addPriority() {
		this.priorities.push(new PriorityDTO());
	}

	selectPriority(priority: PriorityDTO = new PriorityDTO()) {
		this.currentPriorityColor = priority.color;
		this.priorityForm.patchValue(priority);
	}
}
