import { Component, DestroyRef, inject } from '@angular/core';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { RoomDTO } from '../../model';
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { assign } from 'lodash';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, switchMap, takeUntil, takeWhile } from 'rxjs/operators';
import { of } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { MatButton, MatFabButton } from '@angular/material/button';
import { ColorPickerModule } from 'ngx-color-picker';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import {
	MatAccordion,
	MatExpansionPanel,
	MatExpansionPanelHeader,
	MatExpansionPanelTitle,
	MatExpansionPanelDescription,
} from '@angular/material/expansion';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
	selector: 'ft-room-setting',
	templateUrl: './room-setting.component.html',
	styleUrls: ['./room-setting.component.scss'],
	standalone: true,
	imports: [
		MatToolbar,
		MatAccordion,
		MatExpansionPanel,
		MatExpansionPanelHeader,
		MatExpansionPanelTitle,
		MatChipListbox,
		MatChipOption,
		MatExpansionPanelDescription,
		FormsModule,
		ReactiveFormsModule,
		MatFormField,
		MatLabel,
		MatInput,
		MatSelect,
		MatOption,
		ColorPickerModule,
		MatButton,
		MatFabButton,
		MatTooltip,
		MatIcon,
		TranslateModule,
	],
})
export class RoomSettingComponent {
	rooms: RoomDTO[] = [];
	roomForm!: FormGroup;
	currentRoomColor: string = '#25ac5d';

	#destroyRef = inject(DestroyRef);
	private _snack = inject(MatSnackBar);
	private _translate = inject(TranslateService);

	public saving = false;

	constructor(
		private service: SharedService,
		public dialog: MatDialog,
		private fb: FormBuilder,
	) {
		this.roomForm = this.fb.group(
			assign(new RoomDTO(), {
				name: [null, Validators.required],
			}),
		);
		this.getRooms();
	}

	private getRooms(): void {
		this.service
			.getRooms()
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe((data) => {
				this.rooms = data;
				this.saving = false;
			});
	}

	saveRoom(room: RoomDTO) {
		this.saving = true;
		room.color = this.currentRoomColor;
		this.service.createRoom(room)
			.pipe(takeUntilDestroyed(this.#destroyRef))
			.subscribe((res) => {
			if (res) {
				this.roomForm.patchValue(res);
				this._snack.open(this._translate.instant('CHANGES_SAVED'), '', {
					duration: 2000,
				});
				this.getRooms();
			}
		});
	}

	deleteRoom(room: RoomDTO) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.pipe(
				filter((ok) => !!ok),
				switchMap(() => {
					if (room.id) return this.service.deleteRoom(room.id);
					else return of(true);
				}),
				takeUntilDestroyed(this.#destroyRef),
			)
			.subscribe((value) => {
				if (value) this.getRooms();
			});
	}

	addRoom() {
		this.rooms.push(new RoomDTO());
	}

	selectRoom(room: RoomDTO = new RoomDTO()) {
		this.currentRoomColor = room.color;
		this.roomForm.patchValue(room);
	}
}
