import { Component } from "@angular/core";
import { SettingService } from "../setting.service";
import {DEFAULT_SEARCH_CONFIG, ViewerDTO} from "../../model";
import { FormBuilder, FormGroup, Validators, FormsModule, ReactiveFormsModule } from "@angular/forms";
import { assign } from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { DeleteConfirmComponent } from "../../shared";
import { TranslateService, TranslateModule } from "@ngx-translate/core";
import {SearchService} from "../../shared/advanced-search/search.service";
import { MatIcon } from "@angular/material/icon";
import { MatTooltip } from "@angular/material/tooltip";
import { MatButton, MatFabButton } from "@angular/material/button";
import { MatSlideToggle } from "@angular/material/slide-toggle";
import { MatInput } from "@angular/material/input";
import { MatFormField, MatLabel } from "@angular/material/form-field";
import { MatChipListbox, MatChipOption } from "@angular/material/chips";
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, MatExpansionPanelDescription } from "@angular/material/expansion";
import { MatToolbar } from "@angular/material/toolbar";

@Component({
    selector: "ft-viewers",
    templateUrl: "./viewers.component.html",
    styleUrls: ["./viewers.component.scss"],
    providers: [TranslateService],
    standalone: true,
    imports: [
        MatToolbar,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatChipListbox,
        MatChipOption,
        MatExpansionPanelDescription,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSlideToggle,
        MatButton,
        MatFabButton,
        MatTooltip,
        MatIcon,
        TranslateModule,
    ],
})
export class ViewersComponent {
  viewers: ViewerDTO[];
  viewerForm: FormGroup;

  constructor(
    private service: SettingService,
    private fb: FormBuilder,
    private dialog: MatDialog
  ) {
    this.viewerForm = this.fb.group(
      assign(new ViewerDTO(), { name: ["", Validators.required] })
    );
    this.getViewers();
  }

  private getViewers(): void {
    this.service.getViewers().subscribe((data) => (this.viewers = data));
  }

  saveViewer(value: ViewerDTO) {
    this.service.createViewer(value).subscribe((res) => {
      if (res) this.getViewers();
    });
  }

  deleteViewer(viewer: ViewerDTO) {
    this.dialog
      .open(DeleteConfirmComponent)
      .afterClosed()
      .subscribe((ok) => {
        if (ok)
          this.service.deleteViewer(viewer).subscribe((value) => {
            if (value) this.getViewers();
          });
      });
  }

  addViewer() {
    this.viewers.push(new ViewerDTO());
  }

  selectViewer(viewer: ViewerDTO = new ViewerDTO()) {
    this.viewerForm.patchValue(viewer);
  }
}
