<mat-drawer-container
  class="unavailability-drawer-container"
  [hasBackdrop]="false"
>
  <mat-drawer #drawer [opened]="false" [mode]="'over'" [position]="'end'">
    <div class="drawer-container">
      <h4>
        <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
        {{ "EDIT" | translate }} {{ unavailabilityType | translate }}
      </h4>

      <div class="unavailability-form">
        <div class="fx-layout-column" [formGroup]="unavailabilityForm">
          @if (unavailabilityType === 'HOLIDAY') {
          <mat-form-field>
            <mat-label>{{ "TITLE" | translate }}</mat-label>
            <input matInput formControlName="title" />
          </mat-form-field>
          }

          <mat-form-field>
            <mat-date-range-input [rangePicker]="picker">
              <input
                [placeholder]="'FROM' | translate"
                formControlName="startDate"
                matStartDate
              />
              <input
                [placeholder]="'TO' | translate"
                formControlName="endDate"
                matEndDate
              />
            </mat-date-range-input>
            <mat-datepicker-toggle
              [for]="picker"
              matSuffix
            ></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>

          @if (unavailabilityType === 'LEAVE') {
          <mat-form-field>
            <mat-label>{{ "EMPLOYEE" | translate }}</mat-label>
            <mat-select formControlName="employeeId">
              @for (employee of employees; track $index) {
              <mat-option [value]="employee.id">{{
                employee.fullName
              }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "MANAGER" | translate }}</mat-label>
            <mat-select formControlName="managerId">
              @for (manager of managers; track $index) {
              <mat-option [value]="manager.id">{{
                manager.fullName
              }}</mat-option>
              }
            </mat-select>
          </mat-form-field>

          <div class="fx-layout-column-nowrap">
            <h5 style="margin: 6px 0">
              {{ "STATUS" | translate }}
            </h5>
            <mat-button-toggle-group
              aria-label="status"
              formControlName="status"
              name="status"
              class="fx-layout-row-nowrap"
            >
              @for ( status of [ 'PENDING', 'APPROVED', 'REJECTED' ]; track
              $index ) {
              <mat-button-toggle class="fx-grow-1" [value]="status">
                {{ status | translate }}
              </mat-button-toggle>
              }
            </mat-button-toggle-group>
          </div>

          <mat-form-field style="margin-top: 16px">
            <mat-label>{{ "NOTES" | translate }}</mat-label>
            <input matInput formControlName="notes" />
          </mat-form-field>
          }
        </div>
      </div>

      <div class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4">
        <button mat-button color="warn" (click)="drawer.toggle()">
          {{ "CANCEL" | translate }}
        </button>
        <button
          mat-button
          color="primary"
          [disabled]="unavailabilityForm.invalid"
          (click)="
            saveUnavailability(unavailabilityForm.value); drawer.toggle()
          "
        >
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div
      class="fx-layout-row fx-fill fx-content-space-between fx-items-start"
      style="padding: 0 1em; width: calc(100% - 2em)"
    >
      <div class="fx-fill-width fx-layout-row-nowrap">
        <h3>{{ "UNAVAILABILITIES" | translate }}</h3>
      </div>

      <div style="width: calc(67% - 16px)" class="unavailability-table">
        <div
          class="table-unavailability-header fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
        >
          <h5>
            <mat-icon fontIcon="mdi-menu" fontSet="mdi"></mat-icon>
            {{ "LEAVES" | translate }}
          </h5>
          <button
            color="primary"
            (click)="emptyFormFor('LEAVE'); drawer.toggle()"
            mat-raised-button
          >
            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            {{ "ADD" | translate }}
          </button>
        </div>

        <div
          class="fx-search-area ft-header-search fx-layout-row-nowrap fx-gap-4 fx-padding-8 fx-content-start fx-items-center"
        >
          <mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
            <mat-icon
              matIconPrefix
              fontIcon="mdi-magnify"
              fontSet="mdi"
              tabindex="-1"
            ></mat-icon>
            <input matInput #leaveFilter [placeholder]="'SEARCH' | translate" />
          </mat-form-field>
        </div>

        <div class="relative fx-layout-column-nowrap ft-setting-card-width">
          <mat-table
            [dataSource]="leavesDataSource"
            class="fx-grow-1 fx-overflow-auto ft-unavailability-table"
            [trackBy]="trackById"
          >
            <!--Table-->
            @for (col of displayedColumns; track $index) {
            <ng-container
              cdkColumnDef="{{ col.label }}"
              [class.hidden]="col.hidden"
            >
              <mat-header-cell *cdkHeaderCellDef>
                {{ col.header | translate }}</mat-header-cell
              >

              @if (col.type === 'date') {
              <mat-cell *cdkCellDef="let row">
                {{ row[col.label] | date : "dd/MM/yyyy" }}</mat-cell
              >
              } @if (col.type === 'status') {
              <mat-cell *cdkCellDef="let row">
                <div
                  class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-2 ft-badge"
                  [style.background-color]="
                    statusesStyles[row[col.label]].backgroundColor
                  "
                >
                  <mat-icon
                    fontSet="mdi"
                    [fontIcon]="statusesStyles[row[col.label]].icon"
                    [style.color]="statusesStyles[row[col.label]].color"
                  ></mat-icon>
                  <span style="color: black">{{
                    row[col.label] | translate
                  }}</span>
                </div>
              </mat-cell>
              } @if (!['date', 'status'].includes(col.type)) {
              <mat-cell *cdkCellDef="let row"> {{ row[col.label] }}</mat-cell>
              }
            </ng-container>
            }

            <!--actions-->
            <ng-container cdkColumnDef="action">
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                class="fx-layout-row-nowrap fx-content-end fx-items-center"
              >
                <button
                  mat-icon-button
                  (click)="
                    unavailabilityType = 'LEAVE';
                    editUnavailability(row);
                    drawer.toggle()
                  "
                >
                  <mat-icon
                    class="edit-icon-gradient"
                    fontIcon="mdi-pencil"
                    fontSet="mdi"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="deleteUnavailability(row.id, row.type)"
                >
                  <mat-icon
                    class="delete-icon-gradient"
                    fontIcon="mdi-delete"
                    fontSet="mdi"
                  ></mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <!--header-->
            <mat-header-row
              *cdkHeaderRowDef="leaveColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              [@rowsAnimation]=""
              *cdkRowDef="let row; columns: leaveColumns"
            >
            </mat-row>
          </mat-table>

          <mat-paginator
            #leavePaginator
            class="fx-height-46"
            [length]="leavesDataSource?.data?.length ?? 0"
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 50]"
            [showFirstLastButtons]="true"
          >
          </mat-paginator>
        </div>
      </div>
      <div style="width: 33%" class="unavailability-table">
        <div
          class="table-unavailability-header fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
        >
          <h5>
            <mat-icon fontIcon="mdi-menu" fontSet="mdi"></mat-icon>
            {{ "HOLIDAYS" | translate }}
          </h5>
          <button
            color="primary"
            (click)="emptyFormFor('HOLIDAY'); drawer.toggle()"
            mat-raised-button
          >
            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            {{ "ADD" | translate }}
          </button>
        </div>
        <div
          class="fx-search-area ft-header-search fx-layout-row-nowrap fx-gap-4 fx-padding-8 fx-content-start fx-items-center"
        >
          <mat-form-field subscriptSizing="dynamic" class="fx-fill-width">
            <mat-icon
              matIconPrefix
              fontIcon="mdi-magnify"
              fontSet="mdi"
              tabindex="-1"
            ></mat-icon>
            <input
              matInput
              #holidayFilter
              [placeholder]="'SEARCH' | translate"
            />
          </mat-form-field>
        </div>

        <div class="relative fx-layout-column-nowrap ft-setting-card-width">
          <mat-table
            [dataSource]="holidaysDataSource"
            class="fx-grow-1 fx-overflow-auto ft-unavailability-table"
            [trackBy]="trackById"
          >
            <!--Table-->
            @for (col of displayedColumns; track $index) {
            <ng-container
              cdkColumnDef="{{ col.label }}"
              [class.hidden]="col.hidden"
            >
              <mat-header-cell *cdkHeaderCellDef>
                {{ col.header | translate }}</mat-header-cell
              >

              @if (col.type === 'date') {
              <mat-cell *cdkCellDef="let row">
                {{ row[col.label] | date : "dd/MM/yyyy" }}</mat-cell
              >
              } @if (col.type === 'status') {
              <mat-cell *cdkCellDef="let row">
                <span class="ft-badge">{{
                  row[col.label] | translate
                }}</span></mat-cell
              >
              } @if (!['date', 'status'].includes(col.type)) {
              <mat-cell *cdkCellDef="let row"> {{ row[col.label] }}</mat-cell>
              }
            </ng-container>
            }

            <!--actions-->
            <ng-container cdkColumnDef="action">
              <mat-header-cell *cdkHeaderCellDef></mat-header-cell>
              <mat-cell
                *cdkCellDef="let row"
                class="fx-layout-row-nowrap fx-content-end fx-items-center"
              >
                <button
                  mat-icon-button
                  (click)="
                    unavailabilityType = 'HOLIDAY';
                    editUnavailability(row);
                    drawer.toggle()
                  "
                >
                  <mat-icon
                    class="edit-icon-gradient"
                    fontIcon="mdi-pencil"
                    fontSet="mdi"
                  ></mat-icon>
                </button>
                <button
                  mat-icon-button
                  (click)="deleteUnavailability(row.id, row.type)"
                >
                  <mat-icon
                    class="delete-icon-gradient"
                    fontIcon="mdi-delete"
                    fontSet="mdi"
                  ></mat-icon>
                </button>
              </mat-cell>
            </ng-container>

            <!--header-->
            <mat-header-row
              *cdkHeaderRowDef="holidayColumns; sticky: true"
            ></mat-header-row>
            <mat-row
              [@rowsAnimation]=""
              *cdkRowDef="let row; columns: holidayColumns"
            >
            </mat-row>
          </mat-table>

          <mat-paginator
            #holidayPaginator
            class="fx-height-46"
            [length]="holidaysDataSource?.data?.length ?? 0"
            [pageIndex]="0"
            [pageSize]="10"
            [pageSizeOptions]="[5, 10, 25, 50]"
            [showFirstLastButtons]="true"
          >
          </mat-paginator>
        </div>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
