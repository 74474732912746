import { Route } from '@angular/router';
import { AuthGuard, LoginGuard } from './auth';
import { UserResolver } from './user.resolver';
import { ExamPerMonthComponent } from './statistic/exam-per-month/exam-per-month.component';
import { ProfileSettingComponent } from './setting/profile-setting/profile-setting.component';
import { ViewersComponent } from './setting/viewers/viewers.component';
import { FormsSetupComponent } from './setting/forms-setup/forms-setup.component';
import { StaffContractComponent } from './setting/staff-contract/staff-contract.component';
import { EditStaffContractComponent } from './setting/staff-contract/edit-staff-contract/edit-staff-contract.component';
import { AetSettingComponent } from './setting/aet-setting/aet-setting.component';
import { UsersSettingComponent } from './setting/users-setting/users-setting.component';
import { ProcedureCodeSettingComponent } from './setting/procedure-code-setting/procedure-code-setting.component';
import { BillingCodesComponent } from './setting/billing-codes/billing-codes.component';
import { PathologySettingComponent } from './setting/pathology-setting/pathology-setting.component';
import { ImagingCenterSettingComponent } from './setting/imaging-center-setting/imaging-center-setting.component';
import { ModalitySettingComponent } from './setting/modality-setting/modality-setting.component';
import { PrioritySettingComponent } from './setting/priority-setting/priority-setting.component';
import { ReportTemplateSettingComponent } from './setting/report-template-setting/report-template-setting.component';
import { GeneralSettingComponent } from './setting/general-setting/general-setting.component';
import { ReasonForExamComponent } from './setting/reason-for-exam/reason-for-exam.component';
import { RoomSettingComponent } from './setting/room-setting/room-setting.component';
import { DefaultValuesComponent } from './setting/default-values/default-values.component';
import { LabelSettingComponent } from './setting/label-setting/label-setting.component';
import { UnavailabilityComponent } from './setting/unavailability/unavailability.component';
import { PrintingModelsComponent } from './setting/printing-models/printing-models.component';
import { SmsTemplatesComponent } from './setting/sms-templates/sms-templates.component';
import { PeersSettingComponent } from './setting/peers-setting/peers-setting.component';

export const appRoutes: Route[] = [
	{ path: '', pathMatch: 'full', redirectTo: '/scheduler/schedule-manager' },
	{
		path: 'ft-queue',
		loadComponent: () => import('./shared/queue/queue.component').then(c => c.QueueComponent,),
	},
	{
		path: 'login',
		canActivate: [LoginGuard],
		loadComponent: () => import('./auth/login.component').then(c => c.LoginComponent),
	},
	{
		path: '',
		canActivate: [AuthGuard],
		loadComponent: () => import('./main.component').then(c => c.MainComponent),
		children: [
			{
				path: 'physicians',
				loadComponent: () => import('./physicians/physicians-list/physicians-list.component').then(c => c.PhysiciansListComponent),
				resolve: { user: UserResolver },
			},
			{
				path: 'scheduler',
				loadComponent: () => import('./scheduler/scheduler.component').then(c => c.SchedulerComponent),
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'schedule-manager' },
					{
						path: 'schedule-manager',
						loadComponent: () => import('./scheduler/schedule-manager/schedule-manager.component').then(c => c.ScheduleManagerComponent),
						resolve: { user: UserResolver },
					},
					{
						path: 'external-appointments',
						loadComponent: () => import('./scheduler/external-appointments/external-appointments.component').then(c => c.ExternalAppointmentsComponent),
						resolve: { user: UserResolver },
					},
					{
						path: 'appointments-for-result',
						loadComponent: () => import('./scheduler/appointments-for-result/appointments-for-result.component').then(c => c.AppointmentsForResultComponent),
						resolve: { user: UserResolver },
					},
					{
						path: 'calendar',
						loadComponent: () => import('./shared/ft-calendar/ft-calendar.component').then(c => c.FtCalendarComponent),
						resolve: { user: UserResolver },
					},
				],
			},
			{
				path: 'workflow',
				loadComponent: () => import('./workflow/workflow.component').then(c => c.WorkflowComponent),
				resolve: { user: UserResolver },
			},
			{
				path: 'patients',
				loadComponent: () => import('./patient/patient.component').then(c => c.PatientComponent),
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'patients-list' },
					{
						path: 'patients-list',
						loadComponent: () => import('./patient/patient-list/patient-list.component').then(c => c.PatientListComponent),
						resolve: { user: UserResolver },
					},
					{
						path: 'folder/:id',
						loadComponent: () => import('./patient/patient-folder/patient-folder.component').then(
							c => c.PatientFolderComponent,
						),
					},
				],
			},
			{
				path: 'reporting',
				loadComponent: () => import('./reporting/reporting.component').then(c => c.ReportingComponent),
				resolve: { user: UserResolver },
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'reports-list' },
					{
						path: 'reports-list',
						loadComponent: () => import('./reporting/reporting-home/reporting-home.component').then(c => c.ReportingHomeComponent),
						resolve: { user: UserResolver },
					},
					{ path: 'report-edition/:id', loadComponent: () => import('./reporting/reporting-edit/reporting-edit.component').then(c => c.ReportingEditComponent), },
					{ path: 'report-form/:id', loadComponent: () => import('./reporting/reporting-form/reporting-form.component').then(c => c.ReportingFormComponent), },
				]
			},
			{
				path: 'setting',
				loadComponent: () => import('./setting/setting.component').then(c => c.SettingComponent),
				children: [
					{ path: '', pathMatch: 'full', redirectTo: 'schedule-setting' },
					{ path: 'profile-setting', component: ProfileSettingComponent },
					{ path: 'viewers', component: ViewersComponent },
					{ path: 'forms-setup', component: FormsSetupComponent },
					{ path: 'staff-contract', component: StaffContractComponent },
					{
						path: 'edit-staff-contract',
						component: EditStaffContractComponent,
					},
					{ path: 'aet-setting', component: AetSettingComponent },
					{ path: 'users-setting', component: UsersSettingComponent },
					{
						path: 'procedure-code-setting',
						component: ProcedureCodeSettingComponent,
					},
					{ path: 'billing-code-setting', component: BillingCodesComponent },
					{ path: 'pathology-setting', component: PathologySettingComponent },
					{
						path: 'imaging-center-setting',
						component: ImagingCenterSettingComponent,
					},
					{ path: 'modality-setting', component: ModalitySettingComponent },
					{ path: 'priority-setting', component: PrioritySettingComponent },
					{
						path: 'report-template-setting',
						component: ReportTemplateSettingComponent,
					},
					{ path: 'schedule-setting', component: GeneralSettingComponent },
					{ path: 'reason-for-exam', component: ReasonForExamComponent },
					{ path: 'rooms', component: RoomSettingComponent },
					{ path: 'default-values', component: DefaultValuesComponent },
					{ path: 'labels', component: LabelSettingComponent },
					{ path: 'unavailabilities', component: UnavailabilityComponent },
					{ path: 'printing-models', component: PrintingModelsComponent },
					{ path: 'sms-templates', component: SmsTemplatesComponent },
					{ path: 'peers-setting', component: PeersSettingComponent },
				]
			},
			{
				path: 'statistic',
				loadComponent: () => import('./statistic/statistic.component').then(c => c.StatisticComponent),
				resolve: { user: UserResolver },
				children: [
					{ path: '', component: ExamPerMonthComponent, pathMatch: 'full' },
					{ path: 'exams-per-month', component: ExamPerMonthComponent },
				],
			},
			{
				path: 'messaging',
				loadComponent: () =>
					import('./messaging/messaging.component').then(c => c.MessagingComponent,),
			},
			{
				path: 'logs',
				loadComponent: () =>
					import('./shared/logs/logs.component').then(
						c => c.LogsComponent,
					),
				pathMatch: 'full',
			},
		],
	},
	{
		path: 'external-viewer/:study',
		canActivate: [AuthGuard],
		loadComponent: () =>
			import('./external-viewer/external-viewer.component').then(
				c => c.ExternalViewerComponent,
			),
	},
	{
		path: 'doc-reader/:docId',
		canActivate: [AuthGuard],
		loadComponent: () => import('./shared/doc-reader/doc-reader.component').then(c => c.DocReaderComponent),
	},
	{
		path: '**',
		pathMatch: 'full',
		redirectTo: '/scheduler/schedule-manager',
	},
];