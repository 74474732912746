<mat-card
  appearance="outlined"
  [style.padding.px]="0"
  class="fx-layout-column-nowrap fx-fill"
>
  <div
    class="fx-layout-row-nowrap fx-content-start fx-items-stretch"
    style="min-height: 100%"
  >
    <!--Menu-->
    <div class="ft-side-nav fx-layout-column-nowrap" style="width: 260px">
      <div class="fx-grow-1 fx-layout-column fx-content-center fx-items-start">
        @for (view of views['EXAM']; track $index) {
        <button
          (click)="selectView(view, 'EXAM', true)"
          [class.menu-item-selected]="
            currentState === 'EXAM' && view === currentView
          "
          [ngStyle]="{
            display: hasPermission(view) ? 'block' : 'none'
          }"
          mat-button
          class="fx-height-42 fx-fill-width"
          style="text-align: left"
        >
          {{ view | translate }}
        </button>
        } @if (hasPermission('PAYMENT')) {
        <button
          (click)="
            selectedDataType = 'PAYMENTS'; selectView('DATE', 'PAYMENT', true)
          "
          [class.menu-item-selected]="currentState === 'PAYMENT'"
          mat-button
          class="fx-height-42 fx-fill-width"
          style="text-align: left; display: block"
        >
          {{ "PAYMENTS" | translate }}
        </button>
        } @if (hasPermission('PATIENT')) {
        <button
          (click)="
            selectedDataType = 'PATIENTS'; selectView('ALL', 'PATIENT', true)
          "
          [class.menu-item-selected]="currentState === 'PATIENT'"
          mat-button
          class="fx-height-42 fx-fill-width"
          style="text-align: left; display: block"
        >
          {{ "PATIENTS" | translate }}
        </button>
        }

          <!--<button (click)="selectedDataType = 'PATIENTS'; selectView('FUNGIBLE', 'PATIENT', true) "
                  [class.menu-item-selected]="currentState === 'FUNGIBLE'"
                  mat-button
                  class="fx-height-42 fx-fill-width"
                  style="text-align: left; display: block"
          >
              {{ "Fongibles" | translate }}
          </button>-->
      </div>
    </div>

    <!--container-->
    <div class="fx-grow-1 fx-layout-column fx-padding-8">
      @if (filterVisible) {
      <div
        style="margin-bottom: 8px"
        class="relative fx-layout-row fx-content-center fx-items-center fx-gap-4"
        [formGroup]="queryForm"
      >
        @if ( currentState === 'PAYMENT' && hasPermission('PAYMENT') ) {
        <mat-button-toggle-group
          #group2="matButtonToggleGroup"
          formControlName="filter2"
        >
          <mat-button-toggle value="USER">
            {{ "USER_ACCOUNT" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="BOX" checked>
            {{ "BOX" | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>

        @if (group2.value === 'BOX') {
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>{{ "BOX" | translate }}</mat-label>
          <mat-select
            [placeholder]="'BOX' | translate"
            formControlName="filter"
            tabindex="-1"
          >
            @for (option of boxes; track $index) {
            <mat-option [value]="option.name">{{ option.name }} </mat-option>
            }
          </mat-select>
        </mat-form-field>
        } @if (group2.value === 'USER') {
        <mat-form-field subscriptSizing="dynamic">
          <mat-label>{{ "USER_ACCOUNT" | translate }}</mat-label>
          <mat-select
            [placeholder]="'USER_ACCOUNT' | translate"
            formControlName="filter"
            tabindex="-1"
          >
            @for (option of users; track $index) {
            <mat-option [value]="option.fullName"
              >{{ option.fullName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        } } @if ( currentView === 'REFERRING_PHYSICIAN' &&
        hasPermission(currentView) ) {
        <mat-form-field subscriptSizing="dynamic">
          <input
            matInput
            [placeholder]="'REFERRING_PHYSICIAN' | translate"
            [formControl]="referringPhysicianControl"
            [matAutocomplete]="refPhyList"
          />
          <mat-autocomplete
            #refPhyList="matAutocomplete"
            [autoActiveFirstOption]="true"
            (optionSelected)="onChangeRefPhy($event)"
          >
            <ng-container>
              @for ( user of filteredReferringPhysicians; track $index ) {
              <mat-option [value]="user">
                {{ user["fullName"] }}
              </mat-option>
              }
            </ng-container>
          </mat-autocomplete>
          <button (click)="clearInput($event)" mat-icon-button matSuffix>
            <mat-icon fontIcon="mdi-close" fontSet="mdi"></mat-icon>
          </button>
        </mat-form-field>
        } @if ( currentView === 'PERFORMING_RADIOLOGIST' &&
        hasPermission(currentView) ) {
        <mat-form-field subscriptSizing="dynamic">
          <mat-select
            (valueChange)="onChangeUser($event)"
            [placeholder]="'PERFORMING_PHYSICIAN' | translate"
          >
            @for ( physician of physicians; track $index ) {
            <mat-option [value]="physician"
              >{{ physician.fullName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        } @if ( currentView === 'TECHNICIANS' && hasPermission(currentView) ) {
        <mat-form-field subscriptSizing="dynamic">
          <mat-select
            (valueChange)="onChangeUser($event)"
            [placeholder]="'TECHNICIAN' | translate"
          >
            @for ( technician of technicians; track $index ) {
            <mat-option [value]="technician"
              >{{ technician.fullName }}
            </mat-option>
            }
          </mat-select>
        </mat-form-field>
        }

        <mat-form-field subscriptSizing="dynamic" style="width: 132px">
          <input
            matInput
            [matDatepicker]="startPicker"
            formControlName="startDate"
            placeholder="{{ startDateLabel | translate }}"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="startPicker"
          ></mat-datepicker-toggle>
          <mat-datepicker #startPicker></mat-datepicker>
        </mat-form-field>

        @if (currentState !== 'PAYMENT') {
        <mat-form-field subscriptSizing="dynamic" style="width: 132px">
          <input
            matInput
            [matDatepicker]="picker"
            formControlName="endDate"
            placeholder="{{ 'TO' | translate }}"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        } @if (currentState !== 'PAYMENT') {
        <mat-button-toggle-group
          #group="matButtonToggleGroup"
          formControlName="period"
          (change)="updateDateRange(group.value)"
        >
          <mat-button-toggle value="TODAY">
            {{ "TODAY" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="YESTERDAY">
            {{ "YESTERDAY" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="1W">
            {{ "ONE_WEEK" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="1M">
            {{ "ONE_MONTH" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="6M">
            {{ "SIX_MONTHS" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="1Y">
            {{ "ONE_YEAR" | translate }}
          </mat-button-toggle>
          <mat-button-toggle value="OT">
            {{ "ALL" | translate }}
          </mat-button-toggle>
        </mat-button-toggle-group>
        }

        <mat-menu #exportMenu="matMenu" [overlapTrigger]="true">
          <button (click)="exportData('EXCEL')" mat-menu-item>
            <mat-icon
              [style.color]="'#009688'"
              fontIcon="mdi-microsoft-excel"
              fontSet="mdi"
            ></mat-icon>
            Excel
          </button>
          <button
            (click)="exportData('PDF')"
            [disabled]="true"
            mat-menu-item
            style="color: #a0a0a0"
          >
            <mat-icon
              [style.color]="'#a0a0a0'"
              fontIcon="mdi-file-pdf-box"
              fontSet="mdi"
            ></mat-icon>
            PDF
          </button>
        </mat-menu>

        @if (exportButtonVisible && hasPermission('EXPORT')) {
        <button
          [disabled]="!currentPhysicianId"
          [matMenuTriggerFor]="exportMenu"
          class="export-btn"
          mat-button
        >
          <mat-icon fontIcon="mdi-cloud-download" fontSet="mdi"></mat-icon>
        </button>
        }
      </div>
      }

      <ng-template ftState></ng-template>
    </div>
  </div>
</mat-card>
@if (downloading) {
<div
  class="fx-layout-row fx-content-center fx-items-center fixed fx-h-fill fx-w-fill"
  style="top: 0; left: 0; background: rgba(0, 0, 0, 0.4)"
>
  <div
    class="fx-layout-row fx-content-center fx-items-center fx-gap-12"
    style="
      height: 86px;
      width: 360px;
      border-radius: 8px;
      background: rgb(255 255 255);
      color: #585858;
    "
  >
    <mat-spinner [diameter]="30" [strokeWidth]="2"></mat-spinner>
    <h3>{{ "EXPORTING" | translate }}</h3>
  </div>
</div>
}
