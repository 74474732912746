import { BehaviorSubject, merge, of as observableOf } from 'rxjs';

import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import {AfterViewInit, Component, OnDestroy, TemplateRef, viewChild, ViewChild} from '@angular/core';
import { DeleteConfirmComponent, SharedService } from '../../shared';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { MatTableDataSource, MatTable, MatHeaderCell, MatCell, MatHeaderRow, MatRow } from '@angular/material/table';
import { STAFF_HEADER_COLS, STAFF_TABLE_CONF } from './table-conf';
import { rowsAnimation } from '../../animations';
import {DEFAULT_SEARCH_CONFIG, StaffContractDTO} from '../../model';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SearchService} from "../../shared/advanced-search/search.service";
import { TranslateModule } from '@ngx-translate/core';
import { CdkColumnDef, CdkHeaderCellDef, CdkCellDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-staff-contract',
    templateUrl: './staff-contract.component.html',
    styleUrls: ['./staff-contract.component.scss'],
    animations: [rowsAnimation],
    standalone: true,
    imports: [
        MatToolbar,
        MatButton,
        RouterLink,
        MatIcon,
        FormsModule,
        ReactiveFormsModule,
        MatProgressBar,
        MatTable,
        MatSort,
        CdkColumnDef,
        CdkHeaderCellDef,
        MatHeaderCell,
        MatSortHeader,
        CdkCellDef,
        MatCell,
        MatIconButton,
        CdkHeaderRowDef,
        MatHeaderRow,
        CdkRowDef,
        MatRow,
        MatPaginator,
        TranslateModule,
    ],
})
export class StaffContractComponent implements AfterViewInit, OnDestroy {
	cols: any[];
	displayedColumns = [];

	dataSource = new MatTableDataSource<StaffContractDTO>();
	@ViewChild(MatSort, { static: true }) sort: MatSort;
	@ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

	resultsLength = 0;
	isLoadingResults = true;
	isRateLimitReached = false;

	filterChange = new BehaviorSubject('');
	trackById = (_: number, item: any): string => item.id;
	filterForm: FormGroup;

	searchTemplate = viewChild.required<TemplateRef<any>>('searchTemplate');

	constructor(
		private sharedService: SharedService,
		private _searchService: SearchService,
		private _fb: FormBuilder,
		public dialog: MatDialog
	) {

		this.filterForm = this._fb.group({key:''});

		setTimeout(() => {
			this._searchService.searchInputConfig.set({
				placeholder: 'search.contract',
				expandable: false,
				hidden:false,
				template: this.searchTemplate()
			});

			this._searchService.genericSearchObs.subscribe(value => this.filterForm.get('key').patchValue(value));
		});

		this.displayedColumns = STAFF_TABLE_CONF;
		this.cols = STAFF_HEADER_COLS;
	}

	ngOnDestroy() {
		this._searchService.searchInputConfig.set(DEFAULT_SEARCH_CONFIG);
	}

	deleteStaffContract(row: any) {
		this.dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.subscribe(ok => {
				if (ok)
					this.sharedService
						.deleteStaffContract(row.id)
						.subscribe(_ => this.filterChange.next(''));
			});
	}

	ngAfterViewInit() {
		this.filterForm.get('key').valueChanges
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(value => {
				if (!this.dataSource) return;
				this.paginator.pageIndex = 0;
				this.filterChange.next(value);
			});

		this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0));

		const observedFilters = [
			this.sort.sortChange.asObservable(),
			this.paginator.page.asObservable(),
			this.filterChange.asObservable(),
		];

		merge(...observedFilters)
			.pipe(
				startWith({}),
				switchMap(() => {
					this.isLoadingResults = true;
					return this.sharedService.getStaffContracts(
						this.paginator.pageSize,
						this.paginator.pageIndex,
						this.sort.active,
						this.sort.direction,
						this.filterChange.getValue()
					);
				}),
				map(data => {
					this.isLoadingResults = false;
					this.isRateLimitReached = false;
					this.resultsLength = data['totalElements'];

					return data['content'];
				}),
				catchError(() => {
					this.isLoadingResults = false;
					this.isRateLimitReached = true;
					return observableOf([]);
				})
			)
			.subscribe(data => (this.dataSource.data = data));
	}
}
