import { AfterViewInit, Component, DestroyRef, inject, viewChild } from '@angular/core';
import { SettingService } from '../setting.service';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { AetEditComponent } from './aet-edit/aet-edit.component';

import { AetDTO } from '../../model';
import { DeleteConfirmComponent } from '../../shared';
import { MatTableDataSource, MatTable, MatHeaderCell, MatCell, MatHeaderRow, MatRow } from '@angular/material/table';
import { BehaviorSubject, merge, of as observableOf } from 'rxjs';
import { AET_TABLE_CONF } from './table-conf';
import { union } from 'lodash';
import {
	catchError,
	debounceTime, filter,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { NgStyle, DatePipe } from '@angular/common';
import { CdkColumnDef, CdkHeaderCellDef, CdkCellDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
	selector: 'ft-aet-setting',
	templateUrl: './aet-setting.component.html',
	styleUrls: ['./aet-setting.component.scss'],
	standalone: true,
	imports: [
		MatToolbar,
		MatButton,
		MatIcon,
		MatTable,
		MatSort,
		CdkColumnDef,
		CdkHeaderCellDef,
		MatHeaderCell,
		MatSortHeader,
		CdkCellDef,
		MatCell,
		NgStyle,
		MatIconButton,
		MatTooltip,
		CdkHeaderRowDef,
		MatHeaderRow,
		CdkRowDef,
		MatRow,
		MatPaginator,
		DatePipe,
		TranslateModule,
	],
})
export class AetSettingComponent implements AfterViewInit {
	displayedColumns = [];
	availableColumns = [];
	dataSource = new MatTableDataSource<AetDTO>();

	private filterChange = new BehaviorSubject('');


	public sort = viewChild<MatSort>(MatSort);
	public paginator = viewChild<MatPaginator>(MatPaginator);

	#setting = inject(SettingService);
	#dialog = inject(MatDialog);
	#destroyRef = inject(DestroyRef);

	constructor() {
		this.availableColumns = AET_TABLE_CONF;
		this.displayedColumns = union(
			AET_TABLE_CONF.filter(it => !it.hidden).map(it => it.value),
			['action'],
		);
	}

	ngAfterViewInit() {

		const sort = this.sort();
		const paginator = this.paginator();

		sort?.sortChange.subscribe(_ => {
			paginator.pageIndex = 0;
		});

		const observedFilters = [
			sort?.sortChange.asObservable(),
			paginator?.page.asObservable(),
			this.filterChange.pipe(debounceTime(250)),
		];

		merge(...observedFilters)
			.pipe(
				startWith({}),
				switchMap(() => {
					return this.#setting.getAets(
						paginator.pageSize,
						paginator.pageIndex,
						sort.active,
						sort.direction,
					);
				}),
				map(data => data['content'] as AetDTO[]),
				catchError(() => {
					return observableOf([]);
				}),
				takeUntilDestroyed(this.#destroyRef)
			)
			.subscribe(data => (this.dataSource.data = data));
	}

	editAet(aet: AetDTO = new AetDTO()) {
		this.#dialog
			.open(AetEditComponent, {
				data: aet?.id,
			})
			.afterClosed()
			.pipe(filter(value => value), takeUntilDestroyed(this.#destroyRef))
			.subscribe(() => this.filterChange.next(''));
	}

	deleteAet(aet: any) {
		this.#dialog
			.open(DeleteConfirmComponent)
			.afterClosed()
			.pipe(
				filter(ok => ok && aet.id),
				switchMap(() => this.#setting.deleteAet(aet.id)),
				takeUntilDestroyed(this.#destroyRef),
			).subscribe(() => this.filterChange.next(''));
	}
}
