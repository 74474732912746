<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-database-plus"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">{{ "AETEDIT" | translate }}</h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div matDialogContent>
  <form [formGroup]="form">
    <div class="fx-layout-row-nowrap fx-gap-4">
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "NAME" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'NAME' | translate }}"
          formControlName="name"
        />
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "AETITLE" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'AETITLE' | translate }}"
          formControlName="title"
        />
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "INSTALLER" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'INSTALLER' | translate }}"
          formControlName="installer"
        />
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "MANUFACTURER" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'MANUFACTURER' | translate }}"
          formControlName="manufacturer"
        />
      </mat-form-field>
    </div>

    <div class="fx-layout-row-nowrap fx-content-start fx-items-center fx-gap-4">
      <mat-form-field>
        <mat-label>{{ "HOSTNAME" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'HOSTNAME' | translate }}"
          formControlName="hostname"
        />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "ROOM" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'ROOM' | translate }}"
          formControlName="room"
          required
        >
          @for (item of rooms; track $index) {
          <mat-option [value]="item.id">
            {{ item.name }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="fx-layout-row-nowrap fx-gap-4">
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "PURCHASEDATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="purchase"
          placeholder="{{ 'PURCHASEDATE' | translate }}"
          formControlName="dateOfPurchase"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="purchase"
        ></mat-datepicker-toggle>
        <mat-datepicker #purchase></mat-datepicker>
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "INSTALLATIONDATE" | translate }}</mat-label>
        <input
          matInput
          [matDatepicker]="install"
          placeholder="{{ 'INSTALLATIONDATE' | translate }}"
          formControlName="installationDate"
        />
        <mat-datepicker-toggle
          matSuffix
          [for]="install"
        ></mat-datepicker-toggle>
        <mat-datepicker #install></mat-datepicker>
      </mat-form-field>
    </div>

    <div class="fx-layout-row-nowrap fx-gap-4">
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "MODALITY" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'MODALITY' | translate }}"
          formControlName="modalityId"
          class="full-width input-back"
          required
        >
          @for (item of modalities; track $index) {
          <mat-option [value]="item.id" class="option">
            {{ item.value }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "TECHNICIAN" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'TECHNICIAN' | translate }}"
          formControlName="defaultTechnicianId"
        >
          @for (item of technicians; track $index) {
          <mat-option [value]="item.id" class="option">
            {{ item.firstName }} {{ item.lastName }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <div class="ft-color">
      <div>{{ "COLOR" | translate }}:</div>
      <div
        class="color-area"
        [(colorPicker)]="color"
        [style.background]="color"
      ></div>
    </div>

    <div class="space-top fx-layout-row">
      <mat-slide-toggle
        class="fx-grow-1 example-margin"
        formControlName="hasMPPS"
      >
        {{ "HAS_MPPS" | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle
        class="fx-grow-1 example-margin"
        formControlName="enabled"
        [color]="'primary'"
      >
        {{ "AUTHORIZED" | translate }}
      </mat-slide-toggle>
      <mat-slide-toggle
        class="fx-grow-1 example-margin"
        formControlName="setAsDefault"
        [color]="'primary'"
      >
        {{ "SETASDEFAULT" | translate }}
      </mat-slide-toggle>
    </div>
  </form>
</div>
<div matDialogActions align="end" class="p-3">
  <button [mat-dialog-close]="null" color="warn" mat-raised-button>
    {{ "CANCEL" | translate }}
  </button>
  <button
    (click)="onSave(form.value)"
    [disabled]="form.invalid"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</div>
