<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
    <h3 class="text-2xl font-semibold">{{ "ROOMS" | translate }}</h3>
</mat-toolbar>

<div
        class="relative fx-layout-column-nowrap fx-content-center fx-items-center ft-setting-card-width"
        style="padding-top: 24px"
>
    <mat-accordion>
        @for (room of rooms; track $index) {
            <mat-expansion-panel (opened)="selectRoom(room)">
                <mat-expansion-panel-header>
                    <mat-panel-title [style.max-width.px]="160">
                        <mat-chip-listbox>
                            <mat-chip-option
                                    selectable="false"
                            >{{ room.name }}
                            </mat-chip-option
                            >
                        </mat-chip-listbox>
                    </mat-panel-title>
                    <mat-panel-description>
                        {{ room.examType | translate }}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <form [formGroup]="roomForm" class="fx-layout-column">
                    <mat-form-field class="fx-fill-width">
                        <mat-label>{{ "NAME" | translate }}</mat-label>
                        <input
                                matInput
                                placeholder="{{ 'NAME' | translate }}"
                                formControlName="name"
                        />
                    </mat-form-field>

                    <mat-form-field class="fx-fill-width">
                        <mat-label>{{ "DESCRIPTION" | translate }}</mat-label>
                        <mat-select formControlName="examType">
                            @for (eType of ['INPATIENT', 'OUTPATIENT']; track $index) {
                                <mat-option [value]="eType">{{ eType | translate }}</mat-option>
                            }
                        </mat-select>
                    </mat-form-field>

                    <div class="ft-color">
                        <div>{{ "COLOR" | translate }}:</div>
                        <div
                                class="color-area"
                                [(colorPicker)]="currentRoomColor"
                                [style.background]="currentRoomColor"
                        ></div>
                    </div>
                </form>
                <div class="fx-layout-row fx-content-end fx-gap-4">
                    <button mat-button color="warn" (click)="deleteRoom(room)">
                        {{ "DELETE" | translate }}
                    </button>
                    <button
                            mat-raised-button
                            color="primary"
                            (click)="saveRoom(roomForm.value)"
                            [disabled]="roomForm.invalid || saving"
                    >
                        {{ "SAVE" | translate }}
                    </button>
                </div>
            </mat-expansion-panel>
        }
    </mat-accordion>

    <button
            mat-fab
            [matTooltip]="'ADD_NEW' | translate"
            (click)="addRoom()"
            style="position: fixed; bottom: 46px; right: 56px"
    >
        <mat-icon
                class="add-icon-gradient"
                fontIcon="mdi-plus"
                fontSet="mdi"
        ></mat-icon>
    </button>
</div>
