import { Provider } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { MAT_CARD_CONFIG } from '@angular/material/card';
import { MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { FtPaginatorI18n } from '../paginator-i18n';


function paginatorI18n(
	translateService: TranslateService,
): MatPaginatorIntl {
	return new FtPaginatorI18n(translateService).getPaginatorI18n();
}


export function provideMaterialConfig(): Provider[] {
	return [
		{
			provide: MatPaginatorIntl,
			deps: [TranslateService],
			useFactory: (translate: TranslateService) => paginatorI18n(translate),
		},
		{
			provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
			useValue: {
				appearance: 'outline',
				subscriptSizing: 'fixed',
				floatLabel: 'auto',
			},
		},
		{
			provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
			useValue: { horizontalPosition: 'center' },
		},
		{
			provide: MAT_CARD_CONFIG,
			useValue: { appearance: 'outlined' },
		},
		{
			provide: MAT_DIALOG_DEFAULT_OPTIONS,
			useValue: { maxHeight: '100vh', panelClass: 'ftx-dialog' },
		},
	];
}