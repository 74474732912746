import { EnvironmentProviders, importProvidersFrom } from '@angular/core';
import { JwtModule, JwtModuleOptions } from '@auth0/angular-jwt';

const tokenGetter = (): string => localStorage.getItem('access_token');
const JWT_CONFIG: JwtModuleOptions = {
	config: {
		tokenGetter,
		authScheme: 'Bearer ',
		headerName: 'Authorization',
	},
};


export function provideJwtConfig(): EnvironmentProviders {
	return importProvidersFrom(JwtModule.forRoot(JWT_CONFIG));
}