import { APP_INITIALIZER, Provider } from '@angular/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats } from '@angular/material/core';
import { AppConfigService } from '../app-config.service';
import { Moment } from 'moment';
import { MOMENT_FR_SPEC } from '../utils';
import * as moment from 'moment';


function ftDateFormats(_config: AppConfigService): MatDateFormats {
	return {
		parse: {
			dateInput: ['DDMMYYYY', 'MMYYYY', 'YYYY', 'LL'],
		},
		display: {
			dateInput: _config.displayDateInput,
			monthYearLabel: 'MM YYYY',
			dateA11yLabel: 'LL',
			monthYearA11yLabel: 'MM YYYY',
		},
	};
}

function _configureMoment(
	_config: AppConfigService,
	_adapter: DateAdapter<Moment>,
	_momentAdapter: MomentDateAdapter,
) {
	_adapter.setLocale(_config.appLang);
	_momentAdapter.setLocale(_config.appLang);

	moment.updateLocale('fr', MOMENT_FR_SPEC);
	moment.locale(_config.appLang);
}


export function provideMomentConfig(): Provider[] {
	return [
		MomentDateAdapter,
		{
			provide: MAT_DATE_LOCALE,
			deps: [AppConfigService],
			useFactory: (config: AppConfigService) => config.appLocale,
		},
		{
			provide: DateAdapter,
			useClass: MomentDateAdapter,
			deps: [MAT_DATE_LOCALE],
		},
		{
			provide: MAT_DATE_FORMATS,
			deps: [AppConfigService],
			useFactory: ftDateFormats,
		},
		{
			provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
			useValue: { useUtc: true },
		},
		{
			multi: true,
			provide: APP_INITIALIZER,
			deps: [AppConfigService, MomentDateAdapter, DateAdapter],
			useFactory:
				(config: AppConfigService, dateAdapter: DateAdapter<Moment>,
				 momentDateAdapter: MomentDateAdapter,
				) => () => _configureMoment(
					config,
					dateAdapter,
					momentDateAdapter,
				),
		},
	];
}