<mat-toolbar class="fx-card-toolbar fx-height-42 fx-gap-4" color="primary">
  <h3 class="text-2xl font-semibold">{{ "VIEWERS" | translate }}</h3>
</mat-toolbar>

<div
  class="relative fx-layout-column-nowrap fx-content-center fx-items-center ft-setting-card-width"
  style="padding-top: 24px"
>
  <mat-accordion>
    @for (viewer of viewers; track $index) {
    <mat-expansion-panel (opened)="selectViewer(viewer)">
      <mat-expansion-panel-header>
        <mat-panel-title [style.max-width.px]="160">
          <mat-chip-listbox>
            <mat-chip-option style="font-weight: bold" selectable="false">{{
              viewer.name
            }}</mat-chip-option>
          </mat-chip-listbox>
        </mat-panel-title>
        <mat-panel-description>
          {{ viewer.host }}
        </mat-panel-description>
      </mat-expansion-panel-header>
      <div [formGroup]="viewerForm" class="fx-layout-column-nowrap">
        <mat-form-field>
          <mat-label>{{ "NAME" | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'NAME' | translate"
            formControlName="name"
            required
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "REMOTE_PATH" | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'REMOTE_PATH' | translate"
            formControlName="remotePath"
          />
        </mat-form-field>

        <mat-form-field>
          <mat-label>{{ "HOST" | translate }}</mat-label>
          <input
            matInput
            [placeholder]="'HOST' | translate"
            formControlName="host"
          />
        </mat-form-field>

        <div class="fx-layout-row fx-content-start fx-gap-16">
          <mat-slide-toggle
            [style.font-size.px]="13"
            style="margin-bottom: 12px"
            formControlName="defaultViewer"
            >{{ "DEFAULT_VIEWER" | translate }}</mat-slide-toggle
          >
          <mat-slide-toggle
            [style.font-size.px]="13"
            formControlName="osirix"
            >{{ "OSIRIX" | translate }}</mat-slide-toggle
          >
        </div>

        <div class="fx-layout-row-nowrap fx-content-end fx-gap-4">
          <button mat-button color="warn" (click)="deleteViewer(viewer)">
            {{ "DELETE" | translate }}
          </button>
          <button
            mat-raised-button
            color="primary"
            (click)="saveViewer(viewerForm.value)"
          >
            {{ "SAVE" | translate }}
          </button>
        </div>
      </div>
    </mat-expansion-panel>
    }
  </mat-accordion>

  <button
    mat-fab
    [matTooltip]="'ADD_NEW' | translate"
    (click)="addViewer()"
    style="position: fixed; bottom: 46px; right: 56px"
  >
    <mat-icon
      class="add-icon-gradient"
      fontIcon="mdi-plus"
      fontSet="mdi"
    ></mat-icon>
  </button>
</div>
