import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { SettingService } from '../../setting.service';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Profile } from '../../../model';
import { MODULES } from '../table-conf';
import { TranslateModule } from '@ngx-translate/core';
import { MatSlideToggle } from '@angular/material/slide-toggle';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-profile-edit',
    templateUrl: './profile-edit.component.html',
    styleUrls: ['./profile-edit.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIconButton,
        MatDialogClose,
        MatIcon,
        CdkScrollable,
        MatDialogContent,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatCheckbox,
        MatRadioGroup,
        MatRadioButton,
        MatSlideToggle,
        MatDialogActions,
        MatButton,
        TranslateModule,
    ],
})
export class ProfileEditComponent implements OnInit {
	profileForm: FormGroup;
	modules: { name: string; module: string; fields: string[] }[];

	constructor(
		@Inject(MAT_DIALOG_DATA) public data: any,
		private service: SettingService,
		private fb: FormBuilder,
		private dialogRef: MatDialogRef<ProfileEditComponent>
	) {
		this.modules = MODULES;
		this.profileForm = this.fb.group(new Profile());
	}

	onSave(profile: Profile) {
		profile.id = this.data.id;
		this.service
			.saveProfile(profile)
			.subscribe(res => this.dialogRef.close(res));
	}

	ngOnInit() {
		this.profileForm.patchValue(this.data);

		this.profileForm
			.get('admin')
			.valueChanges.subscribe(val =>
				this.modules.forEach(m =>
					this.toggleModulePermissions(m.module, val)
				)
			);
	}

	private toggleModulePermissions(module: any, value: boolean) {
		const _module = this.modules.find(m => m.module === module);
		const fields = _module.fields;
		this.profileForm.get(module).patchValue(value);
		fields.forEach(f =>
			this.profileForm.get(f).patchValue(value ? 'ALL' : 'NONE')
		);
	}
}
