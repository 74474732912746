<mat-toolbar class="ftx-dialog-toolbar" color="primary">
  <mat-icon fontSet="mdi" fontIcon="mdi-playlist-plus"></mat-icon>
  <h3 class="text-lg font-semibold pl-1">
    {{ "PROCEDURECODE" | translate }}
  </h3>
  <span class="fx-grow-1"></span>
  <button mat-icon-button matDialogClose="" tabindex="-1">
    <mat-icon fontSet="mdi" fontIcon="mdi-close"></mat-icon>
  </button>
</mat-toolbar>

<div matDialogContent>
  <form [formGroup]="form">
    <div class="fx-layout-row fx-gap-4">
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "CODE" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'CODE' | translate }}"
          formControlName="code"
        />
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "DURATION" | translate }}</mat-label>
        <input
          type="number"
          matInput
          placeholder="{{ 'DURATION' | translate }}"
          formControlName="duration"
          required
        />
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "BILLING_CODE" | translate }}</mat-label>
        <input
          [formControl]="billingCodeCtrl"
          [matAutocomplete]="autobill"
          matInput
          placeholder="{{ 'BILLING_CODE' | translate }}"
          type="text"
        />
        <mat-autocomplete #autobill="matAutocomplete" autoActiveFirstOption>
          @for (item of filteredBillingCodes | async; track $index) {
          <mat-option
            (onSelectionChange)="billingCodeChange(item)"
            [value]="item.code"
          >
            {{ item.code }}
            @if (selected !== item.id) {
            <span class="list-option-badge">{{ item.price }}</span>
            }
          </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="fx-layout-row">
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "DESCRIPTION" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'DESCRIPTION' | translate }}"
          formControlName="description"
          required
        />
      </mat-form-field>
    </div>

    <div class="fx-layout-row">
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "LONG_DESCRIPTION" | translate }}</mat-label>
        <input
          matInput
          placeholder="{{ 'LONG_DESCRIPTION' | translate }}"
          formControlName="longDescription"
        />
      </mat-form-field>
    </div>

    <div class="fx-layout-row fx-gap-4 space-top">
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "PROCEDURE_TYPE" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'PROCEDURE_TYPE' | translate }}"
          formControlName="reasonForExamId"
          required
        >
          @for (item of reasonForExams; track $index) {
          <mat-option [value]="item.id" class="option">
            {{ item.value }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "DEFAULT_PERFORMING_PHYSICIAN" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'DEFAULT_PERFORMING_PHYSICIAN' | translate }}"
          formControlName="defaultPerformingPhysicianId"
          required
        >
          @for (item of performingPhysicians; track $index) {
          <mat-option [value]="item.id" class="option">
            {{ item.fullName }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "TEMPLATEMODEL" | translate }}</mat-label>
        <input
          [formControl]="templateModelCtrl"
          [matAutocomplete]="auto"
          matInput
          [placeholder]="'TEMPLATEMODEL' | translate"
          type="text"
        />
        <mat-autocomplete #auto="matAutocomplete" autoActiveFirstOption>
          @for (model of filteredModels | async; track $index) {
          <mat-option
            (onSelectionChange)="templateModelChange(model)"
            [value]="model.name"
          >
            {{ model.name }}
          </mat-option>
          }
        </mat-autocomplete>
      </mat-form-field>
    </div>

    <div class="fx-layout-row-nowrap fx-gap-4">
      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "RIS_CODE" | translate }}</mat-label>
        <input
          formControlName="risCode"
          matInput
          placeholder="{{ 'RIS_CODE' | translate }}"
        />
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "MODALITY" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'MODALITY' | translate }}"
          formControlName="modalityId"
          required
        >
          @for (item of modalities; track $index) {
          <mat-option [value]="item.id" class="option">
            {{ item.value }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>

      <mat-form-field class="fx-grow-1">
        <mat-label>{{ "PHARMACEUTICAL" | translate }}</mat-label>
        <mat-select
          placeholder="{{ 'PHARMACEUTICAL' | translate }}"
          formControlName="pharmaceutical"
        >
          @for (item of pharmaceuticals; track $index) {
          <mat-option [value]="item" class="option">
            {{ item }}
          </mat-option>
          }
        </mat-select>
      </mat-form-field>
    </div>

    <mat-slide-toggle formControlName="composed">{{
      "COMPOSED_CODE" | translate
    }}</mat-slide-toggle>
  </form>
</div>
<div matDialogActions class="p-3">
  @if (existingCode) {
  <span class="existing-code">{{ "procedure.code.exists" | translate }}</span>
  }
  <span class="fx-grow-1"></span>
  <button [mat-dialog-close]="null" color="warn" mat-raised-button>
    {{ "CANCEL" | translate }}
  </button>
  <button
    (click)="onSave(form.value)"
    [disabled]="form.invalid || existingCode"
    color="primary"
    mat-raised-button
  >
    {{ "SAVE" | translate }}
  </button>
</div>
