import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Location, NgClass } from '@angular/common';
import { ContractFieldDTO, StaffContractDTO } from '../../../model';
import { SharedService } from '../../../shared';
import { MatTreeNestedDataSource, MatTree, MatTreeNodeDef, MatTreeNode, MatTreeNodeToggle, MatNestedTreeNode, MatTreeNodeOutlet } from '@angular/material/tree';
import { NestedTreeControl } from '@angular/cdk/tree';
import { assign, flatMap, groupBy } from 'lodash';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ArrayUtils } from '../../../utils';
import { AppConfigService } from '../../../app-config.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatCheckbox } from '@angular/material/checkbox';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { MatToolbar } from '@angular/material/toolbar';

class ContractItem {
	constructor(
		public name: string,
		public children?: ContractItem[],
		public level: number = 0
	) {}
}

@Component({
    selector: 'ft-edit-staff-contract',
    templateUrl: './edit-staff-contract.component.html',
    styleUrls: ['./edit-staff-contract.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatIcon,
        MatButton,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatSelect,
        MatOption,
        NgClass,
        MatTree,
        MatTreeNodeDef,
        MatTreeNode,
        MatTreeNodeToggle,
        MatIconButton,
        MatCheckbox,
        MatNestedTreeNode,
        MatTreeNodeOutlet,
        TranslateModule,
    ],
})
export class EditStaffContractComponent implements OnInit {
	form: FormGroup;
	feeTypes = ['MONTHLY', 'DAILY', 'EXAM'];
	calculationBases = ['EXAM_PRICE', 'PAID_AMOUNT'];
	partTypes = ['AMOUNT', 'PERCENTAGE'];

	treeControl = new NestedTreeControl<ContractItem>(node => node.children);
	dataSource = new MatTreeNestedDataSource<ContractItem>();

	examTypeSelectionChange = new Subject<ContractItem[]>();
	procedureCodeSelectionChange = new Subject<ContractItem[]>();
	examTypes = [];

	procedureCodes = [];

	selection = false;

	currencyFormat = 'DH';
	examTypeFormArray: FormArray;

	procedureCodeFormArray: FormArray;

	@ViewChild('name', { static: true }) name: ElementRef;

	hasChild = (_: number, node: ContractItem) =>
		node.children && node.children.length > 0;

	constructor(
		private service: SharedService,
		private location: Location,
		private route: ActivatedRoute,
		private _config: AppConfigService,
		private fb: FormBuilder
	) {
		this.currencyFormat = this._config.generalSetting.currency;
		this.createForm();

		this.service.getProcedureCodes().subscribe(codes => {
			const data = [];
			const exams = groupBy(codes, 'reasonForExam.value');

			Object.keys(exams).forEach(key =>
				data.push(
					new ContractItem(
						key,
						exams[key].map(
							v => new ContractItem(v.description, [], 1)
						)
					)
				)
			);

			this.dataSource.data = data;
		});

		this.examTypeSelectionChange.subscribe(selection => {
			const items = selection.map(
				field => new ContractFieldDTO(field.name)
			);
			items.forEach(c => this.addExamTypeItem(c));

			this.rebuildExamTypeFormArray(items);
		});

		this.procedureCodeSelectionChange.subscribe(selection => {
			const items = selection
				.filter(it => it !== undefined)
				.map(field => new ContractFieldDTO(field.name));
			items.forEach(c => this.addProcedureCodeItem(c));

			this.rebuildProcedureCodeFormArray(items);
		});
	}

	ngOnInit(): void {
		this.name.nativeElement.focus();

		this.route.queryParams
			.pipe(filter(params => params['id']))
			.subscribe(param => {
				this.service.getStaffContract(+param['id']).subscribe(res => {
					this.form.patchValue(res);

					setTimeout(() => {
						res.examTypes.forEach(c => {
							this.addExamTypeItem(c);
							this.examTypes.push(
								this.dataSource.data.find(
									d => d.name === c.name
								)
							);
						});
						res.procedureCodes.forEach(c => {
							this.addProcedureCodeItem(c);
							this.procedureCodes.push(
								flatMap(
									this.dataSource.data,
									c => c.children
								).find(d => d.name === c.name)
							);
						});
					}, 500);
				});
			});
	}

	rebuildExamTypeFormArray(items: ContractFieldDTO[]) {
		this.examTypeFormArray = this.form.get('examTypes') as FormArray;
		const controls = this.examTypeFormArray.controls;

		const fields = items.map(value => value.name);
		controls.forEach(ctrl => {
			if (!fields.includes(ctrl.get('name').value)) {
				controls.splice(controls.indexOf(ctrl), 1);
			}
		});
	}

	rebuildProcedureCodeFormArray(items: ContractFieldDTO[]) {
		this.procedureCodeFormArray = this.form.get(
			'procedureCodes'
		) as FormArray;
		const controls = this.procedureCodeFormArray.controls;

		const fields = items.map(value => value.name);
		controls.forEach(ctrl => {
			if (!fields.includes(ctrl.get('name').value)) {
				controls.splice(controls.indexOf(ctrl), 1);
			}
		});
	}

	addExamTypeItem(c: ContractFieldDTO): void {
		this.examTypeFormArray = this.form.get('examTypes') as FormArray;

		if (
			this.examTypeFormArray.controls.find(
				ctrl => ctrl.get('name').value === c.name
			)
		)
			return;
		const contractForm = this.createContractFieldForm();
		contractForm.patchValue(c);
		this.examTypeFormArray.push(contractForm);
	}

	addProcedureCodeItem(c: ContractFieldDTO): void {
		this.procedureCodeFormArray = this.form.get(
			'procedureCodes'
		) as FormArray;

		if (
			this.procedureCodeFormArray.controls.find(
				ctrl => ctrl.get('name').value === c.name
			)
		)
			return;
		const contractForm = this.createContractFieldForm();
		contractForm.patchValue(c);
		this.procedureCodeFormArray.push(contractForm);
	}

	createContractFieldForm = (): FormGroup =>
		this.fb.group(new ContractFieldDTO());

	onSave(value: StaffContractDTO) {
		this.service.createStaffContract(value).subscribe(res => {
			if (res) this.location.back();
		});
	}

	private createForm = () =>
		(this.form = this.fb.group(
			assign(new StaffContractDTO(), {
				examTypes: this.fb.array([]),
				procedureCodes: this.fb.array([]),
			})
		));

	selectProcedureCode(node: any) {
		ArrayUtils.updateItemInArray(node, this.procedureCodes);
		this.procedureCodeSelectionChange.next(this.procedureCodes);
	}

	selectExamType(node: any) {
		ArrayUtils.updateItemInArray(node, this.examTypes);
		this.examTypeSelectionChange.next(this.examTypes);
	}
	procedureCodeSelected = (node: any): boolean =>
		this.procedureCodes.includes(node);
	examTypeSelected = (node: ContractItem): boolean =>
		this.examTypes.includes(node);

	examTypeChildrenPartial = (node: ContractItem): boolean =>
		node.children.some(value => this.procedureCodes.includes(value));
	cancel = () => this.location.back();
}
