import {
	AfterViewInit,
	Component,
	ElementRef,
	OnDestroy,
	ViewChild,
} from '@angular/core';

import { SettingService } from '../setting.service';

import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HolidayDTO, PhysicianDTO } from '../../model';
import { MatTableDataSource, MatTable, MatHeaderCell, MatCell, MatHeaderRow, MatRow } from '@angular/material/table';
import { union } from 'lodash';
import { UNV_TABLE_CONF } from './table-conf';
import { MatPaginator } from '@angular/material/paginator';
import {
	BehaviorSubject,
	fromEvent as observableFromEvent,
	merge,
	of as observableOf,
	Subscription,
} from 'rxjs';
import {
	catchError,
	debounceTime,
	distinctUntilChanged,
	map,
	startWith,
	switchMap,
} from 'rxjs/operators';
import { rowsAnimation } from '../../animations';
import {
	animate,
	state,
	style,
	transition,
	trigger,
} from '@angular/animations';
import { MatDialog } from '@angular/material/dialog';
import { DeleteConfirmComponent } from '../../shared';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { CdkColumnDef, CdkHeaderCellDef, CdkCellDef, CdkHeaderRowDef, CdkRowDef } from '@angular/cdk/table';
import { MatButton, MatIconButton } from '@angular/material/button';
import { MatButtonToggleGroup, MatButtonToggle } from '@angular/material/button-toggle';
import { MatOption } from '@angular/material/core';
import { MatSelect } from '@angular/material/select';
import { MatDateRangeInput, MatStartDate, MatEndDate, MatDatepickerToggle, MatDateRangePicker } from '@angular/material/datepicker';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatSuffix, MatPrefix } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatDrawerContainer, MatDrawer, MatDrawerContent } from '@angular/material/sidenav';

@Component({
    selector: 'ft-unavailability',
    templateUrl: './unavailability.component.html',
    styleUrls: ['./unavailability.component.scss'],
    animations: [
        rowsAnimation,
        trigger('detailExpand', [
            state('collapsed', style({
                height: '0px',
                minHeight: '0',
                visibility: 'hidden',
                zIndex: '-1',
            })),
            state('expanded', style({ height: '*' })),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
    standalone: true,
    imports: [
        MatDrawerContainer,
        MatDrawer,
        MatIcon,
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        MatLabel,
        MatInput,
        MatDateRangeInput,
        MatStartDate,
        MatEndDate,
        MatDatepickerToggle,
        MatSuffix,
        MatDateRangePicker,
        MatSelect,
        MatOption,
        MatButtonToggleGroup,
        MatButtonToggle,
        MatButton,
        MatDrawerContent,
        MatPrefix,
        MatTable,
        CdkColumnDef,
        CdkHeaderCellDef,
        MatHeaderCell,
        CdkCellDef,
        MatCell,
        MatIconButton,
        CdkHeaderRowDef,
        MatHeaderRow,
        CdkRowDef,
        MatRow,
        MatPaginator,
        DatePipe,
        TranslateModule,
    ],
})
export class UnavailabilityComponent implements AfterViewInit, OnDestroy {
	private _subs: Subscription[] = [];

	statusesStyles = {
		PENDING: {
			icon: 'mdi-timer-sand',
			color: '#FF9800',
			backgroundColor: '#FFF5D7',
		},
		APPROVED: {
			icon: 'mdi-check-decagram',
			color: '#009688',
			backgroundColor: '#D0FFD2',
		},
		REJECTED: {
			icon: 'mdi-close-octagon',
			color: '#FF0000',
			backgroundColor: '#FFDBDB',
		},
	};

	unavailabilityType: 'LEAVE' | 'HOLIDAY';
	unavailabilityForm: FormGroup;
	managers: PhysicianDTO[] = [];
	employees: PhysicianDTO[] = [];
	holidaysDataSource = new MatTableDataSource<HolidayDTO>();
	leavesDataSource = new MatTableDataSource<HolidayDTO>();

	@ViewChild('holidayFilter', { static: true }) holidayFilter: ElementRef;
	@ViewChild('holidayPaginator', { static: true })
	holidayPaginator: MatPaginator;

	@ViewChild('leaveFilter', { static: true }) leaveFilter: ElementRef;
	@ViewChild('leavePaginator', { static: true }) leavePaginator: MatPaginator;

	holidayColumns = union(
		UNV_TABLE_CONF.filter(
			it => !it.hidden && ['BOTH', 'HOLIDAY'].includes(it['table'])
		).map(it => it.label),
		['action']
	);
	leaveColumns = union(
		UNV_TABLE_CONF.filter(
			it => !it.hidden && ['BOTH', 'LEAVE'].includes(it['table'])
		).map(it => it.label),
		['action']
	);

	displayedColumns: any = UNV_TABLE_CONF;

	holidayFilterChange = new BehaviorSubject('@NONE-NONE');
	leaveFilterChange = new BehaviorSubject('@NONE-NONE@ALL');
	trackById = (_: number, item: any): string => item.id;

	constructor(
		private _setting: SettingService,
		private _fb: FormBuilder,
		private _dialog: MatDialog,
		private _snackBar: MatSnackBar,
		private _translate: TranslateService
	) {
		this._createForm();
	}

	ngOnDestroy(): void {
		this._subs.forEach(it => it.unsubscribe());
	}

	ngAfterViewInit() {
		this._getDatasets();
		this._getHolidays();
		this._getLeaves();
	}

	emptyFormFor(holidayType: 'HOLIDAY' | 'LEAVE') {
		const holiday = new HolidayDTO();
		holiday.type = holidayType;
		this.unavailabilityType = holidayType;
		this.unavailabilityForm.patchValue(holiday);
		this.unavailabilityForm.get('status').disable();
	}

	saveUnavailability(value: HolidayDTO) {
		value.employeeName = this.employees.find(
			it => it.id === value.employeeId
		)?.fullName;
		value.managerName = this.managers.find(
			it => it.id === value.managerId
		)?.fullName;
		this._subs.push(
			this._setting.saveUnavailability(value).subscribe(data => {
				if (data) {
					if (value.type === 'HOLIDAY')
						this.holidayFilterChange.next('');
					else this.leaveFilterChange.next('');
				}
			})
		);
	}

	private _createForm() {
		this.unavailabilityForm = this._fb.group(new HolidayDTO());
	}

	editUnavailability(row: HolidayDTO) {
		this.unavailabilityForm.patchValue(row);
		this.unavailabilityForm.get('status').enable();
	}

	deleteUnavailability(id: number, type: any) {
		this._subs.push(
			this._dialog
				.open(DeleteConfirmComponent)
				.afterClosed()
				.subscribe(ok => {
					if (ok)
						this._subs.push(
							this._setting
								.deleteUnavailability(id, type)
								.subscribe(deleted => {
									if (deleted) {
										if (type === 'HOLIDAY')
											this.holidayFilterChange.next('');
										else this.leaveFilterChange.next('');
										this._snackBar.open(
											this._translate.instant(
												'item_deleted'
											),
											'',
											{ duration: 2000 }
										);
									}
								})
						);
				})
		);
	}

	private _getHolidays() {
		observableFromEvent(this.holidayFilter.nativeElement, 'keyup')
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				if (!this.holidaysDataSource) return;
				this.holidayPaginator.pageIndex = 0;
				this.holidayFilterChange.next(
					this.holidayFilter.nativeElement.value
				);
			});

		merge(
			this.holidayPaginator.page.asObservable(),
			this.holidayFilterChange.asObservable()
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					return this._setting.getHolidays(
						this.holidayPaginator.pageSize,
						this.holidayPaginator.pageIndex,
						this.holidayFilterChange.getValue() + '@NONE-NONE@ALL'
					);
				}),
				map(data => data['content'] as HolidayDTO[]),
				catchError(() => observableOf([]))
			)
			.subscribe(data => (this.holidaysDataSource.data = data));
	}

	private _getLeaves() {
		observableFromEvent(this.leaveFilter.nativeElement, 'keyup')
			.pipe(debounceTime(400), distinctUntilChanged())
			.subscribe(() => {
				if (!this.leavesDataSource) return;
				this.leavePaginator.pageIndex = 0;
				this.leaveFilterChange.next(
					this.leaveFilter.nativeElement.value
				);
			});

		merge(
			this.leavePaginator.page.asObservable(),
			this.leaveFilterChange.asObservable()
		)
			.pipe(
				startWith({}),
				switchMap(() => {
					return this._setting.getLeaves(
						this.leavePaginator.pageSize,
						this.leavePaginator.pageIndex,
						this.leaveFilterChange.getValue() + '@NONE-NONE@ALL'
					);
				}),
				map(data => data['content'] as HolidayDTO[]),
				catchError(() => observableOf([]))
			)
			.subscribe(data => (this.leavesDataSource.data = data));
	}

	private _getDatasets() {
		this._subs.push(
			this._setting.getLeavesActors().subscribe(value => {
				this.managers = value.filter(it => it.function_id === 132);
				this.employees = value;
			})
		);
	}
}
