import { Component } from '@angular/core';
import { SharedService } from '../../shared';
import {DEFAULT_SEARCH_CONFIG, PathologyDTO} from '../../model';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {SearchService} from "../../shared/advanced-search/search.service";
import { TranslateModule } from '@ngx-translate/core';
import { MatInput } from '@angular/material/input';
import { MatFormField } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatNavList, MatListItem, MatListItemTitle, MatListItemMeta } from '@angular/material/list';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { MatAccordion, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { MatToolbar } from '@angular/material/toolbar';

@Component({
    selector: 'ft-pathology-setting',
    templateUrl: './pathology-setting.component.html',
    styleUrls: ['./pathology-setting.component.scss'],
    standalone: true,
    imports: [
        MatToolbar,
        MatAccordion,
        MatExpansionPanel,
        MatExpansionPanelHeader,
        MatExpansionPanelTitle,
        MatChipListbox,
        MatChipOption,
        MatNavList,
        MatListItem,
        MatListItemTitle,
        MatIconButton,
        MatListItemMeta,
        MatIcon,
        MatFormField,
        FormsModule,
        ReactiveFormsModule,
        MatInput,
        TranslateModule,
    ],
})
export class PathologySettingComponent {
	public pathologies: PathologyDTO[] = [];
	pathologyForm!: FormGroup;
	categories: string[] = [];
	categoryControl: FormControl = new FormControl<any>('');
	private currentCategory: string;

	constructor(
		private shared: SharedService,
		private sharedService: SharedService,
		private fb: FormBuilder
	) {
		this.pathologyForm = this.fb.group(new PathologyDTO());
		this.getPathologyCategories();
	}

	public getPathologies(category: string, clearArray: boolean = true) {
		if (clearArray) this.pathologies = [];
		this.sharedService
			.getPathologies(category)
			.subscribe(data => (this.pathologies = data));
	}

	private getPathologyCategories() {
		this.shared
			.getPathologyCategories()
			.subscribe(categories => (this.categories = categories));
	}

	onEnter(event: KeyboardEvent) {
		if (event.code === 'Enter') {
			const category = this.categoryControl.getRawValue();

			if (this.currentCategory) {
				this.shared
					.updatePathologyCategory(this.currentCategory, category)
					.subscribe(res => {
						if (res) {
							this.getPathologyCategories();
							this.currentCategory = null;
							this.categoryControl.patchValue('');
						}
					});
			} else {
				this.categories.push(category!);
				this.categoryControl.patchValue('');
			}
		}
	}

	onSavePathology(
		event: KeyboardEvent,
		pathology: PathologyDTO,
		category: string
	) {
		if (event.code === 'Enter') {
			pathology.category = category;

			this.shared.savePathology(pathology).subscribe(_ => {
				this.getPathologies(category, false);
				this.pathologyForm.patchValue(new PathologyDTO());
			});
		}
	}

	editPathology(pathology: PathologyDTO) {
		this.pathologyForm.patchValue(pathology);
	}

	editCategory(category: string) {
		this.currentCategory = category;
		this.categoryControl.patchValue(category);
	}
}
