<mat-drawer-container class="peer-drawer-container" [hasBackdrop]="false">
  <mat-drawer #drawer [opened]="false" [mode]="'over'" [position]="'end'">
    <div class="drawer-container">
      <h4>
        <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
        {{ "EDIT_SERVER" | translate : { serverType } }}
      </h4>

      <div class="dicom-form">
        <div class="fx-layout-column" [formGroup]="serverForm">
          @if (serverType === 'HL7') {
          <mat-form-field>
            <mat-label>{{ "LABEL" | translate }}</mat-label>
            <input matInput formControlName="label" />
          </mat-form-field>
          } @if (serverType === 'CUSTOM') {
          <mat-form-field>
            <mat-label>{{ "LABEL" | translate }}</mat-label>
            <mat-select formControlName="label">
              @for ( system of ['AZ', 'SYNGOVIA']; track $index ) {
              <mat-option [value]="system">{{ system }} </mat-option>
              }
            </mat-select>
          </mat-form-field>
          } @if (serverType === 'DICOM') {
          <mat-form-field>
            <mat-label>{{ "TITLE" | translate }}</mat-label>
            <input matInput formControlName="title" />
          </mat-form-field>
          }

          <div class="fx-layout-row-nowrap fx-gap-4">
            <mat-form-field style="width: 75%">
              <mat-label>{{ "HOSTNAME" | translate }}</mat-label>
              <input matInput formControlName="hostname" />
            </mat-form-field>
            <mat-form-field style="width: 25%">
              <mat-label>{{ "PORT" | translate }}</mat-label>
              <input matInput type="number" formControlName="port" />
            </mat-form-field>
          </div>

          @if (serverType === 'DICOM') {
          <mat-form-field>
            <mat-label>{{ "QUERY_ATTRIBUTE" | translate }}</mat-label>
            <mat-select formControlName="queryAttribute">
              @for ( attr of [ 'studyInstanceUID', 'accessionNumber',
              'patientName' ]; track $index ) {
              <mat-option [value]="attr">{{ attr }} </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "RETRIEVE_METHOD" | translate }}</mat-label>
            <mat-select formControlName="retrieveMethod">
              @for ( method of ['C-MOVE', 'C-GET']; track $index ) {
              <mat-option [value]="method">{{ method }} </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "API_ENDPOINT" | translate }}</mat-label>
            <input matInput formControlName="apiEndpoint" />
          </mat-form-field>
          } @if (serverType === 'HL7') {
          <mat-form-field>
            <mat-label>{{ "SERVICE_TYPE" | translate }}</mat-label>
            <mat-select formControlName="serviceType">
              @for (option of hl7ServiceTypes; track $index) {
              <mat-option [value]="option"
                >{{ option | translate }}
              </mat-option>
              }
            </mat-select>
          </mat-form-field>

          <mat-form-field>
            <mat-label>{{ "SUPPORTED_MESSAGES" | translate }}</mat-label>
            <mat-select formControlName="supportedMessages" multiple>
              @for (msg of hl7Messages; track $index) {
              <mat-option [value]="msg">{{ msg | translate }} </mat-option>
              }
            </mat-select>
          </mat-form-field>
          } @if (serverType === 'CUSTOM') {
          <mat-form-field>
            <mat-label>{{ "QUERY_RANGE_IN_MINUTES" | translate }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="queryRangeInMinutes"
            />
          </mat-form-field>
          } @if (serverType === 'CUSTOM') {
          <mat-form-field>
            <mat-label>{{ "REFRESH_RATE_IN_SECONDS" | translate }}</mat-label>
            <input
              matInput
              type="number"
              formControlName="refreshRateInSeconds"
            />
          </mat-form-field>
          }

          <mat-slide-toggle formControlName="activated">{{
            "ACTIVATED" | translate
          }}</mat-slide-toggle>
          @if (serverType === 'DICOM') {
          <mat-slide-toggle style="margin-top: 12px" formControlName="principal"
            >{{ "PRINCIPAL" | translate }}
          </mat-slide-toggle>
          }
        </div>
      </div>

      <div class="fx-layout-row-nowrap fx-content-end fx-items-center fx-gap-4">
        <button mat-raised-button color="warn" (click)="drawer.toggle()">
          {{ "CANCEL" | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          [disabled]="serverForm.invalid"
          (click)="saveServer(serverForm.value); drawer.toggle()"
        >
          {{ "SAVE" | translate }}
        </button>
      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div
      class="fx-layout-row fx-fill fx-content-space-between fx-items-start fx-gap-16"
      style="padding: 0 1em; width: calc(100% - 2em)"
    >
      <div class="fx-fill-width fx-layout-row-nowrap">
        <h3>{{ "PEERS" | translate }}</h3>
      </div>
      <div style="width: calc(50% - 1em)" class="server-table">
        <div
          class="table-server-header fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
        >
          <h5>
            <mat-icon fontIcon="mdi-menu" fontSet="mdi"></mat-icon>
            {{ "DICOM_SERVERS" | translate }}
          </h5>
          <button
            color="primary"
            (click)="emptyForm(); serverType = 'DICOM'; drawer.toggle()"
            mat-raised-button
          >
            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            {{ "ADD" | translate }}
          </button>
        </div>
        <mat-table [dataSource]="dicomServers" class="fire-table">
          @for (col of displayedColumns; track $index) {
          <ng-container [matColumnDef]="col.label">
            <mat-header-cell *matHeaderCellDef>
              {{ col.header | translate }}
            </mat-header-cell>

            @if (col.header === 'ACTIVATED') {
            <mat-cell *matCellDef="let row">
              <mat-icon
                [ngStyle]="{
                  color: row[col.label] ? 'green' : 'red'
                }"
                fontSet="mdi"
                fontIcon="mdi-circle"
              ></mat-icon>
            </mat-cell>
            } @if (col.header === 'PRINCIPAL') {
            <mat-cell *matCellDef="let row">
              @if (row[col.label]) {
              <mat-icon
                style="color: rgb(57 165 155)"
                fontSet="mdi"
                fontIcon="mdi-check-circle"
              ></mat-icon>
              }
            </mat-cell>
            } @if ( !['ACTIVATED', 'PRINCIPAL'].includes(col.header) ) {
            <mat-cell *matCellDef="let row"> {{ row[col.label] }}</mat-cell>
            }
          </ng-container>
          }

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                (click)="editServer(row); drawer.toggle(); serverType = 'DICOM'"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
              </button>
              <button mat-icon-button (click)="deleteDicomServer(row.id)">
                <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="dicomColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: dicomColumns"></mat-row>
        </mat-table>
      </div>
      <div style="width: calc(50% - 1em)" class="server-table">
        <div
          class="table-server-header fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
        >
          <h5>
            <mat-icon fontIcon="mdi-menu" fontSet="mdi"></mat-icon>
            {{ "HL7_SERVERS" | translate }}
          </h5>
          <button
            color="primary"
            (click)="emptyForm(); serverType = 'HL7'; drawer.toggle()"
            mat-raised-button
          >
            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            {{ "ADD" | translate }}
          </button>
        </div>
        <mat-table [dataSource]="hl7Servers" class="fire-table">
          @for (col of displayedHl7Columns; track $index) {
          <ng-container [matColumnDef]="col.label">
            <mat-header-cell *matHeaderCellDef>
              {{ col.header | translate }}
            </mat-header-cell>
            @if (col.header === 'ACTIVATED') {
            <mat-cell *matCellDef="let row">
              <mat-icon
                [ngStyle]="{
                  color: row[col.label] ? 'green' : 'red'
                }"
                fontSet="mdi"
                fontIcon="mdi-circle"
              ></mat-icon>
            </mat-cell>
            } @if (col.header === 'SERVICE_TYPE') {
            <mat-cell
              *matCellDef="let row"
              [matTooltip]="row[col.label] | translate"
            >
              {{ row[col.label] | translate }}
            </mat-cell>
            } @if ( !['ACTIVATED', 'SERVICE_TYPE'].includes( col.header ) ) {
            <mat-cell *matCellDef="let row"> {{ row[col.label] }}</mat-cell>
            }
          </ng-container>
          }

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                (click)="serverType = 'HL7'; editServer(row); drawer.toggle()"
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
              </button>
              <button mat-icon-button (click)="deleteHl7Server(row.id)">
                <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="hl7Columns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: hl7Columns"></mat-row>
        </mat-table>
      </div>
      <div style="width: calc(50% - 1em)" class="server-table">
        <div
          class="table-server-header fx-layout-row fx-content-space-between fx-items-center fx-gap-4"
        >
          <h5>
            <mat-icon fontIcon="mdi-menu" fontSet="mdi"></mat-icon>
            {{ "CUSTOM_SERVERS" | translate }}
          </h5>
          <button
            color="primary"
            (click)="emptyForm(); serverType = 'CUSTOM'; drawer.toggle()"
            mat-raised-button
          >
            <mat-icon fontIcon="mdi-plus" fontSet="mdi"></mat-icon>
            {{ "ADD" | translate }}
          </button>
        </div>
        <mat-table [dataSource]="customServers" class="fire-table">
          @for (col of displayedCustomColumns; track $index) {
          <ng-container [matColumnDef]="col.label">
            <mat-header-cell *matHeaderCellDef>
              {{ col.header | translate }}
            </mat-header-cell>
            @if (col.header === 'ACTIVATED') {
            <mat-cell *matCellDef="let row">
              <mat-icon
                [ngStyle]="{
                  color: row[col.label] ? 'green' : 'red'
                }"
                fontSet="mdi"
                fontIcon="mdi-circle"
              ></mat-icon>
            </mat-cell>
            } @if (col.header !== 'ACTIVATED') {
            <mat-cell *matCellDef="let row"> {{ row[col.label] }}</mat-cell>
            }
          </ng-container>
          }

          <ng-container matColumnDef="action">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let row">
              <button
                mat-icon-button
                (click)="
                  editServer(row); drawer.toggle(); serverType = 'CUSTOM'
                "
              >
                <mat-icon fontSet="mdi" fontIcon="mdi-pencil"></mat-icon>
              </button>
              <button mat-icon-button (click)="deleteCustomServer(row.id)">
                <mat-icon fontSet="mdi" fontIcon="mdi-delete"></mat-icon>
              </button>
            </mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="customColumns; sticky: true"
          ></mat-header-row>
          <mat-row *matRowDef="let row; columns: customColumns"></mat-row>
        </mat-table>
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
