import { inject, Injectable } from '@angular/core';

import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from './auth';

@Injectable()
export class UserResolver {
	#authService = inject(AuthService);

	resolve(route: ActivatedRouteSnapshot) {
		return this.#authService.checkUser();
	}
}
